import React from "react";
import teamd1 from "../assets/images/team-d-1.jpg";

const TeachersDetails = () => {
    return (
        <section className="team-details">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-5">
                        <div className="team-details__content">
                            <h2 className="team-details__title">Sobre OshyTech</h2>
                            <p className="team-details__text text-justify"><em>"La auto-educación es, estoy convencido, el único tipo de educación que existe"</em></p>
                            <p className="team-details__text text-justify">
                                Esta frase la dijo el gran Isaac Asimov en una entrevista hace ya casi 50 años y no
                                puedo estar más alineado con ella. Por suerte para ti y para mi el conocimiento ahora
                                está al alcance de todo el que se interese por llegar a el.
                            </p>
                            <p className="team-details__text text-justify">
                                <strong>OshyTech</strong> nace con ese concepto, mi objetivo es hacerte llegar los conocimientos sobre
                                desarrollo que voy adquiriendo ya sea por trabajo o por aficiones.
                            </p>
                            <p className="team-details__text text-justify">
                                Además, ya que estamos, si conseguimos sacar algunos proyectos que nos ayuden a todos, mucho mejor, ¿no?
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="team-one__single">
                            <div className="team-one__image">
                                <img src={teamd1} alt=""/>
                            </div>
                            <div className="team-one__content">
                                <h2 className="team-one__name">
                                    <a href="/team-details">Oshy</a>
                                </h2>
                                <p className="team-one__designation">Roger Bosch</p>
                            </div>
                            <div className="team-one__social">
                                <a href="#none">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href="#none">
                                    <i className="fab fa-facebook-square"></i>
                                </a>
                                <a href="#none">
                                    <i className="fab fa-pinterest-p"></i>
                                </a>
                                <a href="#none">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeachersDetails;
