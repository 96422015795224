import React from 'react';
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import TeachersDetails from "../components/TeachersDetails";
import NavThree from "../components/NavThree";
import Faq from "../components/Faq";

const OshyPage = () => {
    return (
        <Layout pageTitle="Oshy | Un poco sobre mi">
            <NavThree active={"oshy"}/>
            <PageHeader title="Sobre mi"/>
            <TeachersDetails/>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-one__single">
                            <div className="page-one__content text-justify">
                                <p className="blog-one__text">
                                    Supongo que te has dado cuenta si has revisado un poco esta página que hago
                                    referencia en varias ocasiones al Keep It Simple.
                                </p>
                                <p className="blog-one__text">
                                    Para mi el desarrollo tiene sentido cómo herramienta para simplificarnos la vida, ya
                                    sea para obtener datos, realizar tareas u ofrecer funcionalidades a usuarios.
                                </p>
                                <p className="blog-one__text">
                                    Esta forma de entender las utilidades de un desarrollo vienen de mi primer código,
                                    hace ya mucho tiempo, cuando Código Facilito no era más que un canal de unos cientos
                                    de personas y antes de empezar mis estudios de informática.
                                </p>
                                <p className="blog-one__text">
                                    Yo siempre he sido un jugador de rol empedernido (aunque las obligaciones no me
                                    permiten jugar tanto como querría), si tienes algo de experiencia en este mundo
                                    sabrás que hacer fichas de PNJ que no van a tener personalidad ni uso es bastante
                                    monotono, y más cuando eres tonto y decides tener todas las fichas bien generadas.
                                </p>
                                <p className="blog-one__text">
                                    ¿Mi solución? Un generador aleatorio de PJ que corría sobre una triste terminal de C
                                    y generaba fichas completas de Warhammer Fantasy 2ed
                                </p>
                                <p className="blog-one__text">
                                    Y así empezó todo, en ese momento me dí cuenta de dos cosas, los quebraderos de
                                    cabeza que te puede dar programar, y lo fácil que te puede llegar a hacer la vida.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Faq/>
            <Footer/>
        </Layout>
    );
};

export default OshyPage;
