import React from 'react';

const Faq = () => {
    return (
        <section className="faq-one">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">¿Esto es gratis?</h2>
                                <p className="faq-one__text">Sí, todo el contenido de OshyTech esta pensado para
                                    ayudarte a aprender o mejorar tus habilidades de desarrollo, y no pretendo ganar
                                    dinero con ello (a no ser que seas una empresa)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">¿Qué ganas con esto?</h2>
                                <p className="faq-one__text">Cómo dicen, explicar lo que aprendes es la mejor manera de
                                    entenderlo mejor y a mi me encanta aprender y enseñar</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">¿Puedo pedirte cosas?</h2>
                                <p className="faq-one__text">¡Claro! Intento responder a todo el mundo si necesitas
                                    alguna cosa, puedes pedir contenido, proyectos o apoyo
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" style={{paddingBottom: `0px`, borderBottom: `0px none`}}>
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">¿Esta web es tuya?</h2>
                                <p className="faq-one__text">Si y no, soy un desastre diseñando cosas, así que me basé
                                    en un theme para Gatsby llamado kipso y lo adapté a mis necesidades
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
